$(function(){
  !(function ($) {
    hideShowPassword.init($);
  })(window.jQuery);

  $('input[type="file"]').on('change',function(){
    if (this.files && this.files[0]) {
        let reader = new FileReader();
        reader.onload = function (e) {
            $('#fileinput-preview-thumbnail').attr('src', e.target.result);
        }
        reader.readAsDataURL(this.files[0]);
    }
  });

  $('form').submit(function(){
      $('.select2.readonly').attr('disabled', false);
  });

  resetPasswordConfirm.init()
});


const hideShowPassword = (function(){
    
  const init = ($) => {
      togglePasswordIcon($);
  };

  const togglePasswordIcon = ($) => {
      $(function () {
          $('[data-toggle="password"]').each(function () {
              let input = $(this);
              let eye_btn = $(this).parent().find(".input-group-text");
              eye_btn.css("cursor", "pointer").addClass("input-password-hide ");
              eye_btn.on("click", function () {
                  if (eye_btn.hasClass("input-password-hide")) {
                      eye_btn.removeClass("input-password-hide").addClass("input-password-show");
                      eye_btn.find(".fa").removeClass("fa-eye-slash").addClass("fa-eye");
                      input.attr("type", "text");
                  } else {
                      eye_btn.removeClass("input-password-show").addClass("input-password-hide");
                      eye_btn.find(".fa").removeClass("fa-eye").addClass("fa-eye-slash");
                      input.attr("type", "password");
                  }
              });
          });
      });
  }

  return {
      init
  }
})()

const resetPasswordConfirm = (function () {
    const init = () => {
      registerEvents();
    };
  
    const registerEvents = () => {
      $('.confirm-reset-password').on('click', handleChange);
    };
  
    const handleChange = function () {
      const title = $(this).data('title');
      const confirmTitle = $('.confirm-title');
      const description = $(this).data('description');
      const confirmDescription = $('.confirm-description');
      confirmDescription.text(description);
      confirmTitle.text(title);
      const formSubmit = $(this).data('href');
      $('form').attr('action', formSubmit);
    };
  
    return {
      init
    };
  })();
  
